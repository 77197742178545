// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package dev.t3mu.huedashboard.models

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf

public fun Configuration.Companion.logins(): Lens<Configuration, LoginInformation?> = lensOf(
    "logins",
    { it.logins },
    { p, v -> p.copy(logins = v)}
  )

public fun <PARENT> Lens<PARENT, Configuration>.logins(): Lens<PARENT, LoginInformation?> = this +
    Configuration.logins()
