// GENERATED by fritz2 - NEVER CHANGE CONTENT MANUALLY!
package dev.t3mu.huedashboard.models

import dev.fritz2.core.Lens
import dev.fritz2.core.lensOf
import kotlin.String

public fun LoginInput.Companion.bridgeAddress(): Lens<LoginInput, String?> = lensOf(
    "bridgeAddress",
    { it.bridgeAddress },
    { p, v -> p.copy(bridgeAddress = v)}
  )

public fun <PARENT> Lens<PARENT, LoginInput>.bridgeAddress(): Lens<PARENT, String?> = this +
    LoginInput.bridgeAddress()

public fun LoginInput.Companion.username(): Lens<LoginInput, String?> = lensOf(
    "username",
    { it.username },
    { p, v -> p.copy(username = v)}
  )

public fun <PARENT> Lens<PARENT, LoginInput>.username(): Lens<PARENT, String?> = this +
    LoginInput.username()
